import { LoginService } from 'src/app/services/login/login.service';
import { Subscription } from 'rxjs';
import { EventsService } from './../services/events/events.service';
import { DynamicFormServiceService } from './../services/dynamicFormService/dynamic-form-service.service';
import { Component, ChangeDetectionStrategy, OnInit, OnChanges, Input } from '@angular/core';
import { Gallery } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgForm} from '@angular/forms';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {Location} from '@angular/common';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component copy.html',
  styleUrls: ['./dynamic-form.component copy.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() pageId;

  @Input() showBackButton = true;

  @Input() fullWidth = false;

  @Input() defaultValue : Map<String,String>;

  form: FormGroup;
  formId;
  formData;
  event;
  eventId;
  hideYTIcon = true;

  selectedLevel = '';

  isLoggedIn: boolean;

  subscription: Subscription;



  constructor(private router: Router, private dynamicFormServiceService: DynamicFormServiceService, public gallery: Gallery,
              public lightbox: Lightbox, private _snackBar: MatSnackBar, private formBuilder: FormBuilder ,
              private activeRoute: ActivatedRoute, private eventsService: EventsService, public _location: Location, private loginService:LoginService) {

                this.router.routeReuseStrategy.shouldReuseRoute = function () {
                  return false;
                };

                this.event = this.router.getCurrentNavigation().extras;
                this.eventId = this.activeRoute.snapshot.paramMap.get('id');

              }

  ngOnInit() {

    // Getting data passed from previous page


    this.subscription = this.loginService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });


    this.formId = this.event._id || this.eventId || this.pageId;

    this.selectedLevel = (this.defaultValue || new Map<String, String>()).get('6143d45c4c01fb089d38b86c') as string;
    this.selectedLevel = (this.defaultValue || new Map<String, String>()).get('6162331554f9f4d7cfbec98c') as string;


    if ( this.formId === null || this.formId === undefined || this.formId === '') {
        this.router.navigateByUrl('/home');
    } else {
      this.loadForm();
    }

    // this.loadEvent();
    // this.loadForm();
  }


  loadEvent() {
// Get a lightbox gallery ref
    const lightboxRef =  this.gallery.ref('lightbox');

    if ( this.event.youTubeVideoID !== undefined && this.event.youTubeVideoID !== null && this.event.youTubeVideoID !== '') {
      // Load items into the lightbox gallery ref
      lightboxRef.reset();
      lightboxRef.addYoutube({ src: this.event.youTubeVideoID });
      this.hideYTIcon = false;
    } else {
      this.hideYTIcon = true;
    }

  }

  loadForm() {
    // Loading Form
    this.dynamicFormServiceService.getDynamicForm(this.formId).subscribe(
      (formData) => {
        if((formData as any).loginRequired && !this.isLoggedIn){
          let navigationExtras: NavigationExtras = {
            queryParams: {
              BACK_LINK: this.router.url
            }
          };
          this.router.navigateByUrl('/login', navigationExtras);
        }

        let json = { };
        if ((formData as any).component != null && (formData as any).component.length > 0 ) {
          this.formData = formData;
          for (let i = 0, l = (formData as any).component.length; i < l; i++) {

            if ((formData as any).component[i].tag === 'submitbutton') {
              continue;
            }
            const findRequired = ((formData as any).component[i].required as boolean) ?  Validators.required : null;
            json[(formData as any).component[i]._id] = [null, (findRequired as Validators)];
          }

          this.form = this.formBuilder.group(json);
        }
      }
    );
  }

  submitForm(message: string, action: string, event: Event) {

    if (!this.form.valid) {

      const snackBar = this._snackBar.open('Please fill the required information', '', {
        duration: 5000,
        panelClass: ['snackbar_no_action']
      });

      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        // tslint:disable-next-line: no-unused-expression
        true && this.form.get(field).touched;
      });
    } else {



      let formAnswer = {
        formId : this.formId,
        eventId : this.formId,
        data : []
       } ;
      let i = 0;
      Object.keys(this.form.controls).forEach(field => {
        // const findRequired = ((this.formData as any).component[i].required as boolean) ?  Validators.required : null;
        formAnswer.data[ i++ ] = {
          fieldLabel : this.formData.component.find(id => id._id === field).label,
          answer : [this.form.get(field).value],
          componentId : field
        };
      });

      this.dynamicFormServiceService.submitForm(formAnswer).subscribe(
        () => {
          const snackBar = this._snackBar.open(message, action, {
            duration: 5000
          });
          snackBar.onAction().subscribe(() => this.router.navigateByUrl('/home') );
          this.form.reset();
        }
      );
    }
  }

  isFieldValid(field: string) {
    // console.log(field);
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  overRideDefault(id){
    return this.defaultValue.get(id);
    // this.selectedLevel = this.defaultValue.get(id)
      // return  == option;
  }


  // submitForm(event: Event) {
  //     if (!this.form.valid) {
  //     Object.keys(this.form.controls).forEach(field => {
  //       const control = this.form.get(field);
  //       control.markAsTouched({ onlySelf: true });
  //     });
  //   }

  //  }


}
