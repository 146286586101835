import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gettheapp',
  templateUrl: './gettheapp.component.html',
  styleUrls: ['./gettheapp.component.scss']
})
export class GettheappComponent {

}
