import { AppComponent } from './../../app.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantsService } from 'src/app/common/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class CorporateService {

  constructor(private httpClient: HttpClient, private appComponent: AppComponent) {
  }

  public getCorporate(id) {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'corporate/get?name='+id);
  }
}
