import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrls: ['./add-to-cart-button.component.scss']
})
export class AddToCartButtonComponent implements OnInit {

  @Input() procedure;

  cartItems = [];

  constructor() {

   }

  ngOnInit() {
    console.log('From button');
    console.log(this.procedure);

    var emcCart = localStorage.getItem("emcCart")

    if( emcCart == null || emcCart == undefined){
      this.cartItems = new Array();
    }else{
      this.cartItems = JSON.parse(emcCart);
    }
  }

  isAlreadyAddedInCart(procedure){

    var result = false;


    this.cartItems.forEach(element => {
      if(element._id === procedure._id){
        result = true;
      }
    });

    return result;

  }

  handleAddToCart(event: Event, procedure: any) {
    console.log(procedure);
    // this.router.navigateByUrl('/checkout', procedure);

    // if(this.isAlreadyAddedInCart(procedure)){
    //   return this.cartIds.push(procedure._id);
    // }


    if( (procedure  as any).quantity == null || (procedure  as any).quantity == undefined){
      (procedure  as any).quantity = 1;
    }

    // (procedure  as any).quantity += 1;
    var index = this.cartItems.findIndex(x => x._id === procedure._id);

    if(index > -1){
      (this.cartItems[index] as any).quantity += 1;
    }else{
      this.cartItems.push(procedure);
    }
    localStorage.setItem("emcCart", JSON.stringify(this.cartItems));

    // console.log(JSON.parse(localStorage.getItem("emcCart")));

  }

  getCartQuantity(procedure:any){

    var index = this.cartItems.findIndex(x => x._id === procedure._id);

    if(index > -1){
      return (this.cartItems[index] as any).quantity;
    }else{
      return 0;
    }

  }


  handleRemoveFromCart($event : Event, procedure: any){

    var index = this.cartItems.findIndex(x => x._id === procedure._id);

    if(index < 0){
      return;
    }

    if((this.cartItems[index] as any).quantity > 1){
      (this.cartItems[index] as any).quantity -= 1;
    }else{
      this.cartItems.splice(index, 1);
    }

    localStorage.setItem("emcCart", JSON.stringify(this.cartItems));

  }

}
