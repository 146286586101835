import { Coupon } from './../services/coupon/coupon.service';
import { CouponDialog } from './../dialog/coupon/coupon-dialog';
import { CheckoutDialog } from './../dialog/checkout/checkout-dialog';
import { MatDialog } from '@angular/material';
import { ConstantsService } from './../common/services/constants.service';
import { WindowRefService } from './../services/windowRef/window-ref.service';
import { Component, OnInit, OnDestroy, NgZone, HostListener } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { CartService, Payment } from '../services/cart/cart.service';
import { LoginService } from '../services/login/login.service';
import { error } from 'protractor';
import { CheckoutService } from 'paytm-blink-checkout-angular';
import { Subscription } from 'rxjs';
import { ICustomWindow } from '../services/windowRef/window-ref.service';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  checkOutButtonHandler : number;

  loader = 'initial';
  error = 'none';
  errorBody = 'flex';
  showCart = false;
  usermessage = 'initial';
  confirmation = 'hidden';

  buttonloader: string;

  cartItems = [];


  procedureExtra: any;
  procedure: {
    procedure: any;
    hospital: any;
    price: any;
    rOrderId: string;
  };
  cartId: string;
  restore: boolean;

  coupon: Coupon;


  cartData = [];

  page = 0;

  pagination = false;

  previousButton = 'rgb(0, 108, 141)';
  nextButton = 'rgb(0, 108, 141)';

  previousButtonClick = 'none';
  nextButtonClick = 'none';

  @HostListener('window:resize', ['$event'])
  isMoileView() {
    if (window.innerWidth < 700) {
      return true;
    }
    return false;
  }


  constructor(private zone: NgZone, private winRef: WindowRefService, private router: Router, private cartService: CartService, private loginService: LoginService, private readonly checkoutService: CheckoutService, public dialog: MatDialog ) {

    this.buttonloader = 'none';

  }

  showCartData() {
    this.errorBody = 'none';
    this.showCart = true;
  }

  cartItemSelected(cartItems){

    var isSelected = false;

    cartItems.forEach(element => {
      if(element.isSelected){
        isSelected = true;
      }
    });

    return isSelected;
  }

  getTotalPrice(cartItems, applyCoupon){

    var sum = 0;
    cartItems.forEach(element => {
      if(element.isSelected){
        sum += (element.price * Math.abs(element.quantity) );
      }
    });

    var discount = 0;

    // cartItems.forEach(element => {
    //   if(element.isSelected && this.coupon != null && this.coupon != undefined && this.coupon.applicableOn.indexOf(element._id)){
    //     discount += element.price - (this.coupon.discountPercentage / 100 ) * element.price;
    //   }
    // });

    cartItems.forEach(element => {
      if(element.isSelected && this.coupon != null && this.coupon != undefined && this.coupon.applicableOn.indexOf(element._id) > -1){
        discount += (element.price * Math.abs(element.quantity));
      }
    });

    if(applyCoupon && this.coupon != null && this.coupon != undefined){
      sum = sum - (this.coupon.discountPercentage / 100 ) * discount;
    }

    return sum;
  }

  selectCompleteCart(event){
    this.cartItems.forEach(element => {
      element.isSelected = event.target.checked;
    });
  }

  handleError(error){
    this.loader = 'none';
    this.showCart = false;
    this.usermessage = 'none';
  }

  ngOnInit() {

    var cart = localStorage.getItem("emcCart")

    var emcCart = [];

    if( cart == null || cart == undefined){
      emcCart = new Array();
    }else{
      emcCart = JSON.parse(cart);
    }


    this.cartItems = new Array();
    emcCart.forEach(cartItem => {

      var item = {
        procedureMetadata : (cartItem as any).procedureMetadata,
        isSelected : true,
        hospital: (cartItem as any).hospital,
        price: (cartItem as any).price,
        quantity: (cartItem as any).quantity,
        _id: (cartItem as any)._id
      }

      this.cartItems.push(item);

    });

    this.showCartData();

    this.checkIfUserLoggedIn();

    this.getCartList(this.page);
  }

  checkIfUserLoggedIn(){
    this.loginService.checkStatus().subscribe(
          (response) => {
            if(response){
              console.log(this.checkOutButtonHandler);
              this.checkOutButtonHandler = 201;
            }else{
              console.log(this.checkOutButtonHandler);
              this.checkOutButtonHandler = 401;
            }
          },error =>{
            this.checkOutButtonHandler = 401;
          });
  }

  handleRemoveFromCart(procedureId: any, all){

    var index = this.cartItems.findIndex(x => x._id === procedureId);

    if(index < 0){
      return;
    }

    if(!all && (this.cartItems[index] as any).quantity > 1){
      (this.cartItems[index] as any).quantity -= 1;
    }else{
      this.cartItems.splice(index, 1);
    }

    localStorage.setItem("emcCart", JSON.stringify(this.cartItems));

  }

  handleCheckout() {

    // console.log(this.cartItems);
    switch(this.checkOutButtonHandler){
      case 100:
        this.router.navigateByUrl('/home');
        break;
      case 201:
        this.buildOrder();
        break;
      case 202:
        break;
      case 401:
        this.router.navigateByUrl('/login', (this.procedure as any));
        break;
      case 504:
          break;
    }
  }

  buildOrder(){

     var selectedItems = this.cartItems.filter(obj => {
        return obj.isSelected;
      })

    const dialogRef = this.dialog.open(CheckoutDialog, {
      data: {cartItems: selectedItems, total: this.getTotalPrice(selectedItems,true), coupon: this.coupon}
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      console.log(result);
      this.coupon = null;


      for( var i = 0; i < this.cartItems.length; i++){
        if ( this.cartItems[i].isSelected) {
            this.cartItems.splice(i, 1);
            i--;
        }
    }

    localStorage.setItem("emcCart", JSON.stringify(this.cartItems));

    this.getCartList(this.page);

    });
  }

  showCoupons(){

    var selectedItems = this.cartItems.filter(obj => {
      return obj.isSelected;
    })

    const dialogRef = this.dialog.open(CouponDialog, {
      data: {cartItems: selectedItems}
    });

    dialogRef.afterClosed().subscribe((result: Coupon) => {
      if(result != null && result != undefined){
        this.coupon = result;
      }
    });
  }

  ngOnDestroy(): void {

  }

  showPaginationBar() {
    return this.pagination;
  }

  handleNext(event: Event) {
    this.getCartList(++this.page);
  }

  handlePrevious(event: Event) {
    this.getCartList(--this.page);
  }

  getCartList( page ) {

    const pageSize = this.isMoileView() ? 6 : 10;

    this.cartService.cartDetails(page,pageSize).subscribe(
      (response) => {
        this.cartData = (response as any).data;
        this.page = (response as any).page;
        if ((response as any).hasPrevious === true) {
          this.previousButton = '#EAEBED';
          this.previousButtonClick = 'all';
          this.pagination = true;
        } else {
          this.previousButton = 'rgb(0, 108, 141)';
          this.previousButtonClick = 'none';
        }

        // Next Button
        if ((response as any).hasNext === true) {
          this.nextButton = '#EAEBED';
          this.nextButtonClick = 'all';
          this.pagination = true;
        } else {
          this.nextButton = 'rgb(0, 108, 141)';
          this.nextButtonClick = 'none';
        }
      }
    );
  }

  getReadableDate(milliSeconds){
    var date = new Date(milliSeconds);
    return date.toLocaleString();
  }



}
