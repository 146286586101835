import { Procedure } from './../banner-search/banner-search.component';
import { CartComponent } from './../cart/cart.component';
import { Component, Input, OnInit } from '@angular/core';
import { ProcedureService, ProcedureListPayload } from '../services/procedure/procedure.service';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { HostListener } from '@angular/core';


@Component({
  selector: 'app-hospitallist',
  templateUrl: './hospitallist.component copy.html',
  styleUrls: ['./hospitallist.component copy.scss']
})
export class HospitallistComponent implements OnInit {

  @Input()
  procedureDetails;

  loader = 'initial';
  error = 'none';
  errorBody = 'flex';
  noresponse = 'none';

  // previousButton = '#EAEBED';
  previousButton = 'rgb(0, 108, 141)';
  nextButton = 'rgb(0, 108, 141)';

  previousButtonClick = 'none';
  nextButtonClick = 'none';


  page;

  pagination = false;

  cartItems = [];

  cartIds = new Array();

  procedureListPayload = new ProcedureListPayload();


  hasNext = false;
  hasPrevious = false;

  procedures;

  showPaginationBar() {
    return this.pagination;
  }

  @HostListener('window:resize', ['$event'])
  isMoileView() {
    if (window.innerWidth < 700) {
      return true;
    }
    return false;
  }

  constructor(public procedureService: ProcedureService,  public activateRoute: ActivatedRoute, public router: Router) {

  }

  ngOnInit() {

    var emcCart = localStorage.getItem("emcCart")

    if( emcCart == null || emcCart == undefined){
      this.cartItems = new Array();
    }else{
      this.cartItems = JSON.parse(emcCart);
    }

    this.getHospitalList(this.activateRoute.snapshot.paramMap.get('id'), 0, 'procedureMetadataId' , 'ASC');
  }

  handleNext(event: Event) {
    this.getHospitalList(this.activateRoute.snapshot.paramMap.get('id'), ++this.page, 'procedureMetadataId' , 'ASC');
  }

  handlePrevious(event: Event) {
    this.getHospitalList(this.activateRoute.snapshot.paramMap.get('id'), --this.page, 'procedureMetadataId' , 'ASC');
  }

  getHospitalList( id, page, sort, sortOrder ) {
    window.scrollTo(0, 0);
    this.procedureListPayload.value = id;
    this.procedureListPayload.pageSize = this.isMoileView() ? 6 : 10;
    this.procedureListPayload.page = page;
    this.procedureListPayload.sortBy = sort;
    this.procedureListPayload.sortOrder = sortOrder;

    this.procedureService.getProcedureHospitals(this.procedureListPayload).subscribe(
      (procedures) => {
        this.errorBody = 'none';
        this.procedures = (procedures as any).data;
        this.page = (procedures as any).page;
        // Previous Button
        if((procedures as any).hasPrevious === true){
          this.hasPrevious = true;
          this.previousButton = '#EAEBED';
          this.previousButtonClick = 'all';
          this.pagination = true;
        } else {
          this.hasPrevious = false;
          this.previousButton = 'rgb(0, 108, 141)';
          this.previousButtonClick = 'none';
        }

        // Next Button
        if((procedures as any).hasNext === true){
          this.hasNext = true;
          this.nextButton = '#EAEBED';
          this.nextButtonClick = 'all';
          this.pagination = true;
        } else {
          this.hasNext = false;
          this.nextButton = 'rgb(0, 108, 141)';
          this.nextButtonClick = 'none';
        }

        if (this.procedures.length <= 0) {
          this.errorBody = 'flex';
          this.loader = 'none';
          this.noresponse = 'initial';
         }
      },
      () => {
        this.loader = 'none';
        this.error = 'initial';
      }
    );
  }

  handleCheckout(event: Event, procedure: any) {
    console.log(procedure);
    this.router.navigateByUrl('/checkout', procedure);
  }

  handleAddToCart(event: Event, procedure: any) {
    // console.log(procedure);
    // this.router.navigateByUrl('/checkout', procedure);

    // if(this.isAlreadyAddedInCart(procedure)){
    //   return this.cartIds.push(procedure._id);
    // }

    (procedure  as any).procedureMetadata = this.procedureDetails;

    if( (procedure  as any).quantity == null || (procedure  as any).quantity == undefined){
      (procedure  as any).quantity = 1;
    }

    // (procedure  as any).quantity += 1;
    var index = this.cartItems.findIndex(x => x._id === procedure._id);

    if(index > -1){
      (this.cartItems[index] as any).quantity += 1;
    }else{
      this.cartItems.push(procedure);
    }
    localStorage.setItem("emcCart", JSON.stringify(this.cartItems));

    // console.log(JSON.parse(localStorage.getItem("emcCart")));

  }

  handleRemoveFromCart($event : Event, procedure: any){

    var index = this.cartItems.findIndex(x => x._id === procedure._id);

    if(index < 0){
      return;
    }

    if((this.cartItems[index] as any).quantity > 1){
      (this.cartItems[index] as any).quantity -= 1;
    }else{
      this.cartItems.splice(index, 1);
    }

    localStorage.setItem("emcCart", JSON.stringify(this.cartItems));

  }

  isAlreadyAddedInCart(procedure){

    var result = false;

    this.cartItems.forEach(element => {
      if(element._id === procedure._id){
        result = true;
      }
    });

    return result;

  }

  getCartQuantity(procedure:any){

    var index = this.cartItems.findIndex(x => x._id === procedure._id);

    if(index > -1){
      return (this.cartItems[index] as any).quantity;
    }else{
      return 0;
    }

  }


}
