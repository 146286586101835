import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, timer } from 'rxjs';
import { Component, OnInit, Input, Pipe, PipeTransform, OnDestroy, ElementRef } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserDetails } from '../services/login/login.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material';

@Component({
  selector: 'app-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss']
})
export class GuestLoginComponent implements OnInit, OnDestroy {


  countDown: Subscription;
  counter = 60;
  tick = 1000;

  form: FormGroup;

  cartId: string;

  loader: string;
  guesttext: string;
  phoneNumber: string;
  otp: string;
  error: string;
  userDetails: UserDetails;

  otpSend: boolean = false;

  canResendOTP = false;

  @Input()
  bottomSheet: MatBottomSheet;

  @Input()
  backLink;


  constructor(private loginService: LoginService, private router: Router, private location: Location, private activeRoute: ActivatedRoute,
              private formBuilder: FormBuilder, private elementRef:ElementRef, private _snackBar: MatSnackBar ) {
                this.userDetails = new UserDetails();
                this.loader = 'none';
                this.error = '';
                this.cartId = this.activeRoute.snapshot.paramMap.get('id');
                this.otpSend = false;

  }

  ngAfterViewInit() {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      phoneNumber: [null, [Validators.required]],
    });
  }

  ngOnDestroy() {
    this.countDown = null;
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { noWhitespaceValidator: true };
  }

  handleOTP(event: Event) {
    this.form.addControl('otp', new FormControl(null));
    this.otp = '';
    if (!this.form.valid) {
      this.openSnackBar('Phone Number is not Valid',null);
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    } else {
      this.error = '';
      this.userDetails.firstName = this.form.get('phoneNumber').value;
      this.userDetails.phoneNumber = this.form.get('phoneNumber').value;
      this.userDetails.email = this.form.get('phoneNumber').value + '@guest.com';
      this.loginService.otp(this.userDetails).subscribe(
        (response) => {
          this.loader = 'none';
          this.guesttext = 'visible';
          this.error = '';
          if ((response as any).statusCode === '301') {
            this.error = (response as any).error;
          } else {
            this.otpSend = true;
            this.counter = 60;
            this.countDown = timer(0, this.tick).subscribe(() => {
              if(this.counter == 0){
                this.countDown.unsubscribe();
                this.countDown = null;
                this.canResendOTP = true;
                return 0;
              }
              this.canResendOTP = false;
              return --this.counter;
            });
          }
        });

    }
  }

  handleLogin(event: Event) {

    this.form = this.formBuilder.group({
      phoneNumber: [null, [Validators.required]],
      otp: [null, [Validators.required]],
    });
    this.form.controls['phoneNumber'].setValue(this.phoneNumber);
    this.form.controls['otp'].setValue(this.otp);

    if (!this.form.valid) {
      this.openSnackBar('Enter OTP',null);
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    } else {
    this.error = '';
    // Setting up user DTO

    this.userDetails.phoneNumber = this.form.get('phoneNumber').value;
    this.userDetails.email = this.form.get('phoneNumber').value + '@guest.com';
    this.userDetails.otp = this.form.get('otp').value;

    this.loginService.guestLogin(this.userDetails).subscribe(
    (response) => {
      this.loader = 'none';
      this.guesttext = 'visible';
      this.error = '';
      if ((response as any).statusCode === '301') {
        this.error = (response as any).error;
        this.openSnackBar('OTP is not valid',null);
      } else {
          this.loginService.loggedIn(true);
          if (this.cartId !== undefined && this.cartId !== null) {
            let navigationExtras: NavigationExtras = {
              queryParams: {
                cartId: this.cartId
              }
            };
            this.router.navigateByUrl('/checkout', navigationExtras);
            return;
          }



          if(this.bottomSheet!=undefined){
            this.bottomSheet.dismiss();
          }

          // this.router.navigateByUrl('/home');
      }
    },
    (error) => {
      this.error = error.error.message;
      this.loader = 'none';
      this.guesttext = 'visible';
    }
    );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['snackbar_no_action']
    });
  }

}

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    // return (('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2)
    return (('00' + Math.floor(value - minutes * 60)).slice(-2)
  );
  }
}
