import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';

export class ConstantsService {
  public static readonly tabTitle: string = 'Easy My Care';
  public static readonly title: string = 'EasyMyCare';

  // public static readonly baseEndpoint: string = 'http://localhost:8080/';

  public static readonly baseEndpoint: string = 'https://api.easymycare.com/';



  private static readonly prod = {
    razor_pay_key_id : "rzp_live_3WFEpMkgGfyg7D",
    emc_tele_consult_id : "6161ff4509b70d327fd21cee",
    emc_org_demo_form : "617fb8bf09b70d327fd21d8a",
    santoba_user_verification_form : "617fb95809b70d327fd21d90"
  }

  private static readonly dev = {
    razor_pay_key_id : "rzp_test_c0GF4Ngt4bD8TA",
    emc_tele_consult_id : "61620d3c4ffde864e69a679d",
    emc_org_demo_form : "61622f6954f9f4d7cfbec986",
    santoba_user_verification_form : "617da9111e976b5012205fc2"

  }

  public static readonly config: any = ConstantsService.prod;


  public static selectedToolbarTab: string = "";


}
