import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';
import { CouponService, Coupon } from './../services/coupon/coupon.service';
import { ProcedureService } from './../services/procedure/procedure.service';
import { LoginService } from './../services/login/login.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import {Location} from '@angular/common';
import { ConstantsService } from '../common/services/constants.service';
import { CheckoutDialog } from '../dialog/checkout/checkout-dialog';

@Component({
  selector: 'app-consult-online',
  templateUrl: './consult-online.component.html',
  styleUrls: ['./consult-online.component.scss']
})
export class ConsultOnlineComponent implements OnInit, OnDestroy, OnChanges {

  data: Map<String,String> = new Map();

  showBackButton: boolean =  true;

  pageId = '';

  subscription: Subscription;

  isLoggedIn: boolean;

  coupon: Coupon;

  cartItems = [];

  loggedInUser : any;

  patientName : string = '';

  patientPhoneNumber;

  consultation = '';

  procedures = [];

  selectedProcedure;

  couponCode = 'ORG_SPECIALIST_CONSULT'

  category;

  constructor(public route: ActivatedRoute,  public _location: Location, private loginService: LoginService,
    private procedureService: ProcedureService, private couponService: CouponService, public dialog: MatDialog,private router: Router, private _snackBar: MatSnackBar) {

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.pageId = this.route.snapshot.paramMap.get('pageId');
    this.category = this.route.snapshot.paramMap.get('category');

    if(this.category == "TELE_CONSULTATION"){
      this.couponCode = "ORG_TELE_CONSULT"
    }

    this.consultation = this.pageId;

      this.procedureService.getProcedure(this.pageId,this.category || 'SPECIALIST_CONSULTATION').subscribe( data=> {
        if(data!=null){
          this.procedures = (data as any).data;
          this.selectProcedure((this.procedures[0] as any)._id);

        console.log(this.procedures[0]);
        this.couponCode = route.snapshot.queryParamMap.get('coupon') || this.couponCode;
        if(this.isLoggedIn || this.couponCode == 'Dietician50'){

          this.couponService.applyCoupon(this.couponCode,this.procedures).subscribe( (data: Coupon) => {
            if(data != null && data != undefined){
              this.coupon = data;
            }else{
              this.coupon = undefined;
            }
          });
        }
        }
      })

   }

   selectProcedure(procedureId){
    this.procedures.forEach(obj => {
      obj.isSelected = false;
      obj.quantity = 1;
    })

    this.selectedProcedure = this.procedures.filter(obj => {
      return obj._id == procedureId;
    });

    this.selectedProcedure.forEach(element => {
      element.isSelected = true;
      element.quantity = 1;
    });

   }


  ngOnInit() {
    console.log('eee ' + this.coupon);

    this.subscription = this.loginService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(this.isLoggedIn){
        this.loginService.getUser().subscribe(user=>{
          this.loggedInUser = user;
          this.patientName = (this.loggedInUser as any).firstName + ' ' + (this.loggedInUser as any).lastName;
          this.patientPhoneNumber = (this.loggedInUser as any).phoneNumber;
        });
      }
    });

  }

  ngOnChanges() {
    console.log(this.isLoggedIn);
    // console.log(this.loginService.isLoggedIn);
  }


  checkout(){

    if(!this.isLoggedIn){
      this.loginService.loginSheet(true);
      // let navigationExtras: NavigationExtras = {
      //   queryParams: {
      //     BACK_LINK: this.router.url
      //   }
      // };
      // this.router.navigateByUrl('/login', navigationExtras);
      // this.router.navigate(['login']);
      return;
    }

    if( this.patientName == undefined || this.patientName == '' || this.patientPhoneNumber == undefined || this.patientPhoneNumber == ' ' ){
        this.openSnackBar('Please fill the form',null);
        return;
    }

    this.cartItems = new Array();

    var selectedItems = this.procedures.filter(obj => {
      return obj.isSelected;
    })

    var item = {
      procedureMetadata : selectedItems[0].procedureMetadata,
      isSelected : true,
      hospital: selectedItems[0].hospital,
      price: selectedItems[0].price,
      quantity: 1,
      _id: selectedItems[0]._id,
      additional:{patientName:this.patientName,patientPhoneNumber:this.patientPhoneNumber,consultation:this.consultation}
    }

    this.cartItems.push(item);

    this.buildOrder();
  }

  buildOrder(){


   const dialogRef = this.dialog.open(CheckoutDialog, {
     data: {cartItems: this.cartItems, total: this.getTotalPrice(this.cartItems,true), coupon: this.coupon}
   });

   dialogRef.afterClosed().subscribe((result: boolean) => {
     console.log(result);
    //  this.coupon = null;


   });
 }

  isCouponApplicable(id){
    if(this.coupon != undefined && this.coupon.applicableOn.indexOf(id)>=0){
      return true;
    }
    return false;

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['snackbar_no_action']
    });
  }

  getTotalPrice(cartItems, applyCoupon){



    var sum = 0;
    cartItems.forEach(element => {

        sum += (element.price * Math.abs(element.quantity) );

    });



    var discount = 0;

    // cartItems.forEach(element => {
    //   if(element.isSelected && this.coupon != null && this.coupon != undefined && this.coupon.applicableOn.indexOf(element._id)){
    //     discount += element.price - (this.coupon.discountPercentage / 100 ) * element.price;
    //   }
    // });

    cartItems.forEach(element => {
      if((this.coupon != null && this.coupon != undefined && this.coupon.applicableOn.indexOf(element._id) > -1)){
        discount += (element.price * Math.abs(element.quantity));
      }
    });

    if(applyCoupon && this.coupon != null && this.coupon != undefined){
      sum = sum - (this.coupon.discountPercentage / 100 ) * discount;
    }

    return sum;
  }

  ngOnDestroy() {
    // tslint:disable-next-line: no-unused-expression
    this.subscription && this.subscription.unsubscribe();
  }



}
