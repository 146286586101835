import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';
import { CouponService, Coupon } from './../services/coupon/coupon.service';
import { ProcedureService } from './../services/procedure/procedure.service';
import { LoginService } from './../services/login/login.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import {Location} from '@angular/common';
import { ConstantsService } from '../common/services/constants.service';
import { CheckoutDialog } from '../dialog/checkout/checkout-dialog';

@Component({
  selector: 'app-tele-consultation',
  templateUrl: './tele-consultation.component.html',
  styleUrls: ['./tele-consultation.component.scss']
})
export class TeleConsultationComponent implements OnInit, OnDestroy, OnChanges {

  data: Map<String,String> = new Map();

  showBackButton: boolean =  true;

  pageId = '';

  subscription: Subscription;

  isLoggedIn: boolean;

  procedureDetail;

  coupon: Coupon;

  cartItems = [];

  loggedInUser : any;

  patientName : string = '';

  patientPhoneNumber : string = '';

  emc_tele_consult_id = ConstantsService.config.emc_tele_consult_id;

  couponCode = 'ORG_TELE_CONSULT'

  constructor(public route: ActivatedRoute,  public _location: Location, private loginService: LoginService,
    private procedureService: ProcedureService, private couponService: CouponService, public dialog: MatDialog,private router: Router, private _snackBar: MatSnackBar) {
    this.pageId = this.route.snapshot.paramMap.get('pageId');
    this.data.set('6143d45c4c01fb089d38b86c',this.pageId);


    this.procedureService.getProcedureDetails(this.emc_tele_consult_id).subscribe(
      (procedureDetails) => {
        this.procedureDetail = procedureDetails;
        (this.procedureDetail as any).quantity = 1;
        (this.procedureDetail as any).isSelected = true;
        console.log(this.procedureDetail);
        this.couponCode = route.snapshot.queryParamMap.get('coupon') || 'ORG_TELE_CONSULT';
        if(this.isLoggedIn){
          this.couponService.applyCoupon(this.couponCode,[this.procedureDetail]).subscribe( (data: Coupon) => {
            if(data != null && data != undefined){
              this.coupon = data;
            }else{
              this.coupon = undefined;
            }
          });
        }


      });

   }



  ngOnInit() {
    console.log('eee ' + this.coupon);

    this.subscription = this.loginService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(this.isLoggedIn){
        this.loginService.getUser().subscribe(user=>{
          this.loggedInUser = user;
          this.patientName = (this.loggedInUser as any).firstName + ' ' + (this.loggedInUser as any).lastName;
          this.patientPhoneNumber = (this.loggedInUser as any).phoneNumber;
          this.couponCode = this.route.snapshot.queryParamMap.get('coupon') || 'ORG_TELE_CONSULT';
          if(this.isLoggedIn){
          this.couponService.applyCoupon(this.couponCode,[this.procedureDetail]).subscribe( (data: Coupon) => {
            if(data != null && data != undefined){
              this.coupon = data;
            }else{
              this.coupon = undefined;
            }
          });
        }
        });
      }
      console.log("is logged in? " + this.isLoggedIn);
    });

  }

  ngOnChanges() {
    console.log(this.isLoggedIn);
    // console.log(this.loginService.isLoggedIn);
  }


  setSubmitButtonLink(){
    if(this.isLoggedIn){

    } else{

    }
  }

  checkout(){

    if(!this.isLoggedIn){
      this.loginService.loginSheet(true);
      // let navigationExtras: NavigationExtras = {
      //   queryParams: {
      //     BACK_LINK: 'tele-consult?coupon='+this.couponCode
      //   }
      // };
      // this.router.navigateByUrl('/login', navigationExtras);
      // this.router.navigate(['login']);
      return;
    }

    console.log(this.patientName);
    console.log(this.patientPhoneNumber);
    if( this.patientName == undefined || this.patientName == '' || this.patientPhoneNumber == undefined || this.patientPhoneNumber == ' ' || this.patientPhoneNumber == '' ){
        this.openSnackBar('Please fill the form',null);
        return;
    }

    this.cartItems = new Array();

    var item = {
      procedureMetadata : this.procedureDetail.procedureMetadata,
      isSelected : true,
      hospital: this.procedureDetail.hospital,
      price: this.procedureDetail.price,
      quantity: 1,
      _id: this.procedureDetail._id,
      additional:{patientName:this.patientName,patientPhoneNumber:this.patientPhoneNumber}
    }

    this.cartItems.push(item);

    this.buildOrder();
  }

  buildOrder(){


   const dialogRef = this.dialog.open(CheckoutDialog, {
     data: {cartItems: this.cartItems, total: this.getTotalPrice(this.cartItems,true), coupon: this.coupon}
   });

   dialogRef.afterClosed().subscribe((result: boolean) => {
     console.log(result);
    //  this.coupon = null;


   });
 }

  isCouponApplicable(id){
    if(this.coupon == undefined || this.coupon.applicableOn.indexOf(id)<0){
      return true;
    }
    return false;

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['snackbar_no_action']
    });
  }

  getTotalPrice(cartItems, applyCoupon){

    var sum = 0;
    cartItems.forEach(element => {
      if(element.isSelected){
        sum += (element.price * Math.abs(element.quantity) );
      }
    });

    var discount = 0;

    // cartItems.forEach(element => {
    //   if(element.isSelected && this.coupon != null && this.coupon != undefined && this.coupon.applicableOn.indexOf(element._id)){
    //     discount += element.price - (this.coupon.discountPercentage / 100 ) * element.price;
    //   }
    // });

    cartItems.forEach(element => {
      if(element.isSelected && this.coupon != null && this.coupon != undefined && this.coupon.applicableOn.indexOf(element._id) > -1){
        discount += (element.price * Math.abs(element.quantity));
      }
    });

    if(applyCoupon && this.coupon != null && this.coupon != undefined){
      sum = sum - (this.coupon.discountPercentage / 100 ) * discount;
    }

    return sum;
  }

  ngOnDestroy() {
    // tslint:disable-next-line: no-unused-expression
    this.subscription && this.subscription.unsubscribe();
  }



}
