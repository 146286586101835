import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  slides = [
    {userName: "Amarjeet Raushan",comment:"I am thankful to EASYMYCARE for facilitating with rapid consultation with Dr. Shivesh. I was in a new town and in a quarantine facility at college campus and had no other option than tele-consultation, and requesting the prescribed medicines to be arranged for healing. Dr. Shivesh sir was too humble and called me instantly."},
    {userName: "Ashish",comment:"Thank you for your consultation service. It really worked in a wonderful way. Result was visible from first doze of medicine. Very good docter, who thoroughly listened the problem of my daughter and solved it with precision. Would be great service if it continues like this."},
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "autoplay": true};


  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }

}
