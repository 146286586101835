import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart/cart.service';
import { LoginService } from '../services/login/login.service';
import { HostListener } from '@angular/core';



@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  cartData;

  page = 0;

  pagination = false;

  previousButton = 'rgb(0, 108, 141)';
  nextButton = 'rgb(0, 108, 141)';

  previousButtonClick = 'none';
  nextButtonClick = 'none';


  @HostListener('window:resize', ['$event'])
  isMoileView() {
    if (window.innerWidth < 700) {
      return true;
    }
    return false;
  }

  showPaginationBar() {
    return this.pagination;
  }

  constructor(private cartService: CartService, private loginService: LoginService) {
    this.loginService.checkStatus().subscribe(response => {
      console.log('WAIT');
    });
  }

  ngOnInit() {
    this.getCartList(this.page);
  }

  handleNext(event: Event) {
    this.getCartList(++this.page);
  }

  handlePrevious(event: Event) {
    this.getCartList(--this.page);
  }

  getCartList( page ) {
    window.scrollTo(0, 0);


    const pageSize = this.isMoileView() ? 6 : 10;

    this.cartService.cartDetails(page,pageSize).subscribe(
      (response) => {
        this.cartData = (response as any).data;
        this.page = (response as any).page;
        if ((response as any).hasPrevious === true) {
          this.previousButton = '#EAEBED';
          this.previousButtonClick = 'all';
          this.pagination = true;
        } else {
          this.previousButton = 'rgb(0, 108, 141)';
          this.previousButtonClick = 'none';
        }

        // Next Button
        if ((response as any).hasNext === true) {
          this.nextButton = '#EAEBED';
          this.nextButtonClick = 'all';
          this.pagination = true;
        } else {
          this.nextButton = 'rgb(0, 108, 141)';
          this.nextButtonClick = 'none';
        }
      }
    );
  }

}
