import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/services/login/login.service';
import { UserService, UserDetails } from './../services/user/user.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnChanges {

  addresses = [{},{},{},{},{},{}];

  isEdit = false;

  user: UserDetails;

  localDob;

  public files: any[];

  profileImage: string | SafeUrl = "../../assets/images/team/male-placeholder-image.jpg";

  slideConfigPackage = {"slidesToShow": 4, "slidesToScroll": 1,"responsive": [{'breakpoint': 616,'settings': {"slidesToShow": 1}}]};


  constructor(private sanitizer: DomSanitizer, private userService: UserService, private LoginService: LoginService,private _snackBar: MatSnackBar) {
    this.files = [];
    this.LoginService.getUser().subscribe((result: UserDetails) =>{
      if(result!=null && result!=undefined){
        this.user = result;
        this.localDob = new Date(this.user.dob).toISOString().split('T')[0];
        this.profileImage = result.profileImage;
        this.user.fullName = this.user.firstName + " " + this.user.lastName;
      }
    });
  }

  addSlide() {
  }

  removeSlide() {
    this.addresses.length = this.addresses.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {

  }

  afterChange(e) {

  }

  beforeChange(e) {

  }

  ngOnChanges() {
  }

  ngOnInit() {

  }

  OnChanges(){

  }

  calculateAge(birthday) {
    if(birthday==null || birthday == undefined){
      return "";
    }
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  updateUserDetails(){

    if(this.localDob !=null && this.localDob != undefined){
      this.user.dob = new Date(this.localDob).getTime().toString();
    }

    this.userService.updateUser(this.user).subscribe( result =>{

      if(result){
        this.LoginService.isRefreshUser(true);
        this.openSnackBar("Profile Updated",null);
      }
      else{
        this.openSnackBar("Please try again after sometime",null);
      }


    });
  }



  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['snackbar_no_action']
    });
  }

  onFileChanged(event: any) {
    this.files = event.target.files;
    this.userService.updateProfilePic(this.files[0]).subscribe( data => {
      if(data){
        this.LoginService.isRefreshUser(true);
        this.profileImage = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(this.files[0])
        );
      };
    })
  }

}
