import { Coupon, CouponService } from './../../services/coupon/coupon.service';
import { WindowRefService } from '../../services/windowRef/window-ref.service';
import { ConstantsService } from '../../common/services/constants.service';
import { CartService } from '../../services/cart/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, Inject, NgZone } from '@angular/core';
import { identifierModuleUrl, preserveWhitespacesDefault } from '@angular/compiler';
import { MatDialog, MatDialogRef, MatMenuModule, MAT_DIALOG_DATA } from '@angular/material';




@Component({
  templateUrl: '../coupon/coupon-dialog.html',
  styleUrls: ['../coupon/coupon-dialog.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CouponDialog implements OnInit {

  showLoader = false;

  couponId = '';

  error = false;

  coupon : Coupon;

  cartItems = [];


  constructor(
    public dialogRef: MatDialogRef<CouponDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Object, private _snackBar: MatSnackBar, private couponService: CouponService) {
      dialogRef.disableClose = false;
      this.cartItems = data["cartItems"];
    }

    ngOnInit(): void {

    }


    doAction(){
      this.dialogRef.close(this.coupon)
    }

    closeDialog(){
      this.dialogRef.close(null);
    }

    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
        duration: 2000,
        panelClass: ['snackbar_no_action']
      });
    }

    checkPromoCode(){
      this.error = false;
      this.couponService.applyCoupon(this.couponId.toUpperCase(),this.cartItems).subscribe( (data: Coupon) => {
        if(data != null && data != undefined){
            this.coupon = data;
            this.doAction();
        }else{
            this.error = true;
        }
      })

    }

}

