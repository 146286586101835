import { Coupon } from './../coupon/coupon.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private httpClient: HttpClient) { }

  public buildCart(procedures: any, couponId: string) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'cart/build?couponId=' + couponId,
    procedures, { withCredentials: true});
  }
  public cartCheckout(receiptId: string, paymentDetails: any) {
      return this.httpClient.post(ConstantsService.baseEndpoint + 'cart/checkout?receiptId=' + receiptId, paymentDetails,
      { withCredentials: true});
  }

  public cartRestore(cartId: string) {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'cart/restore?cartId=' + cartId);
  }
  public cartDetails(page, pageSize) {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'cart/cartdetails?page=' + page + '&pageSize=' + pageSize,
    { withCredentials: true});
  }

  public payment(cartId: string) {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'cart/payment?cartId=' + cartId ,
    { withCredentials: true});
  }

}

export interface PaymentBody{
  txnToken: string
}

export interface Payment{
  body:PaymentBody
}
