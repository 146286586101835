import { AppComponent } from './../../app.component';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { WebStorageService, LOCAL_STORAGE } from 'angular-webstorage-service';



@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  lat;
  lon;
  city;

  constructor(private httpClient: HttpClient, private appComponent: AppComponent) {
  }

  public getProcedureMetadata(page: number, pageSize: number, order: string, category: string) {
    this.lat = this.appComponent.getFromLocal('lat');
    this.lon = this.appComponent.getFromLocal('lon');
    this.city = this.appComponent.getFromLocal('city');

    return this.httpClient.get(ConstantsService.baseEndpoint + 'proceduremetadata?' + 'page=' +
      page + '&pageSize=' + pageSize + '&order=' + order + '&category=' + category + '&lat='
      + this.lat + '&lon=' + this.lon + '&city=' + this.city);
  }

  public getProcedureMetaDataDetails(id: string) {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'proceduremetadata/details?' + 'id=' + id);
}

public getProcedureDetails(id: string) {
  return this.httpClient.get(ConstantsService.baseEndpoint + 'procedure/get/' + id);
}

public getProcedure(name: string,category: string) {
  return this.httpClient.get(ConstantsService.baseEndpoint + 'procedure/get/' + name +'/'+category);
}

public getProcedureHospitals(procedureListPayload: ProcedureListPayload) {
  return this.httpClient.post(ConstantsService.baseEndpoint + 'procedure', procedureListPayload);
}

}

export class ProcedureListPayload {

  key: string;
  value: string;
  pageSize: number;
  page: number;
  sortOrder: string;
  sortBy: string;

}
