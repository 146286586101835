import { Component, OnInit, Inject } from '@angular/core';
import { ConstantsService } from './common/services/constants.service';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { LoginService } from '../app/services/login/login.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  isLoggedIn = false;

  tab;

  constructor(private titleService: Title, private router: Router, private loginService: LoginService,
              @Inject(LOCAL_STORAGE) private storage: WebStorageService ) {
    this.titleService.setTitle(ConstantsService.tabTitle);
    this.loginService.checkStatus().subscribe(
      (response) => {
        if ( response === true) {
          this.isLoggedIn = true;
          this.loginService.loggedIn(true);
          console.log('Success');
        } else {
          console.log('Failed From App');
        }
      },
      (callerror) => {
          console.log('Error');
      });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        ga('set', 'page', evt.urlAfterRedirects);
        ga('send', 'pageview');
        window.scrollTo(0, 0);
    });
  }

  saveInLocal(key, val): void {
    this.storage.set(key, val);
   }

  getFromLocal(key) {
    return this.storage.get(key);
   }

   menuChangeEvent($event){
    this.tab = $event;
   }

   decode(){
     console.log('hey');
   }




}


