import { ConstantsService } from 'src/app/common/services/constants.service';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';
import { CorporateService } from './../services/corporate/corporate.service';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emc',
  templateUrl: './emc.component.html',
  styleUrls: ['./emc.component.scss']
})
export class EmcComponent implements OnInit {


  corporateShortName;

  corporateDetails;

  data: Map<String,String> = new Map();

  isLoggedIn: boolean;

  subscription: Subscription;

  demoPageId = ConstantsService.config.emc_org_demo_form;


  corporatePathDetails = {
    heading:'Welcome',
    buttonText:'Login',
    buttonClickLink:'/login',
  }


  constructor(private router: Router, public route: ActivatedRoute, private corporate: CorporateService, private loginService: LoginService) {
    this.corporateShortName = this.route.snapshot.paramMap.get('orgId');
    this.corporate.getCorporate(this.corporateShortName).subscribe( response => {
      if( response != null ){
        this.corporateDetails = response;
      }
    });

    this.data.set('6162331554f9f4d7cfbec98c','Organization Size');
  }

  ngOnInit() {
    this.subscription = this.loginService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(this.isLoggedIn){
      this.loginService.getUser().subscribe( user => {
        console.log(user);

        if((this.corporateDetails || {}).corporateId === (user as any).org){
          // alert('matched');
          this.corporatePathDetails.buttonText = 'Explore Services'
          this.corporatePathDetails.buttonClickLink = '/home'
        }
        else if((this.corporateDetails ||{}).corporateId === 'santoba' && 'santoba' != (user as any).org){
          // alert('matched');
          this.corporatePathDetails.buttonText = 'Verify It\'s you'
          this.corporatePathDetails.buttonClickLink = '/page/' + ConstantsService.config.santoba_user_verification_form
        }
        else{
          this.corporatePathDetails.buttonText = 'Book a Demo'
          this.corporatePathDetails.buttonClickLink = '/page/' + ConstantsService.config.emc_org_demo_form
        }

      })
    }
    });

  }

  click(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        BACK_LINK: this.router.url
      }
    };
    this.router.navigateByUrl(this.corporatePathDetails.buttonClickLink, navigationExtras);
  }


  scroll(id) {
    const yOffset = -140;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

}
