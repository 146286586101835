import { ConstantsService } from './../common/services/constants.service';
import { Component, OnInit, Input, OnChanges, OnDestroy, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { LoginService } from '../services/login/login.service';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { MatBottomSheet } from '@angular/material';
import { Overlay } from '@angular/cdk/overlay';



@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnChanges, OnDestroy {

  showTopNotification = false;

  @Output()
  tabEmitter : EventEmitter<string> = new EventEmitter();

  tab = '';

  @Input()
  isLoggedIn: boolean;

  subscription: Subscription;

  subscriptionLoginSheet: Subscription;

  refreshUser: Subscription;

  title: string;

  loggedInUser: any;

  loginSheetRef;

  @ViewChild('templateBottomSheet', {static: false}) TemplateBottomSheet: TemplateRef<any>;

  constructor( private loginService: LoginService, private router: Router, private location: Location,private bottomSheet: MatBottomSheet,private _overlay: Overlay) {
    this.title = ConstantsService.title;
  }
  ngOnInit() {
    this.subscription = this.loginService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      this.fetchUser();
    });

    this.subscriptionLoginSheet = this.loginService.showLoginSheet$.subscribe(loginSheet => {
      if(loginSheet){
        this.handleLogin();
      }
    });

    this.refreshUser = this.loginService.refreshUser$.subscribe(val => {
      if(val){
        this.fetchUser();
        this.loginService.isRefreshUser(false);
      }
    });

  }

  fetchUser(){
    if(this.isLoggedIn){
      this.loginService.getUser().subscribe( user => {
        this.loggedInUser = user;
      });
    }
  }

  ngOnDestroy() {
    // tslint:disable-next-line: no-unused-expression
    this.subscription && this.subscription.unsubscribe();
    this.subscriptionLoginSheet && this.subscriptionLoginSheet.unsubscribe();
  }

  ngOnChanges() {
    console.log(this.isLoggedIn);
    // console.log(this.loginService.isLoggedIn);
  }

  handleLogin(){
    this.loginService.loginSheet(false);
    // this.loginSheetRef = this.bottomSheet.open(this.TemplateBottomSheet,
    //   {scrollStrategy: this._overlay.scrollStrategies.noop()});
    this.loginSheetRef = this.bottomSheet.open(this.TemplateBottomSheet,
      {scrollStrategy: this._overlay.scrollStrategies.reposition()});
  }

  handleLogout( event ) {
    this.loginService.logout().subscribe(
      (response) => {
        if ( response === true) {
          this.isLoggedIn = false;
          this.loginService.loggedIn(false);
          this.router.navigateByUrl('/refresh', { skipLocationChange: true}).then(
            () => {
              this.router.navigateByUrl(this.location.path());
            }
          );
        } else {
        }
      },
      (callerror) => {
      });
  }

  scroll(id) {
    ConstantsService.selectedToolbarTab = id;
    this.router.navigate(['/home']);
    const yOffset = -140;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  increaseCount(){

    var cart = localStorage.getItem("emcCart")

    var emcCart = [];

    if( cart == null || cart == undefined){
      emcCart = new Array();
    }else{
      emcCart = JSON.parse(cart);
    }

    var sum = 0;

    emcCart.forEach(cartItem => {
        sum += (cartItem as any).quantity;
    });

    return sum;

  }


}
