import { Component, Input, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { ProcedureService } from '../services/procedure/procedure.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component copy.html',
  styleUrls: ['./procedure.component copy.scss']
})
export class ProcedureComponent implements OnInit {

  @Input()
  category;

  @Input()
  detailsDirection = "left";

  @Input()
  showDetails = false;

  title = "";

  subheading = "";

  loader = 'initial';
  error = 'none';
  errorBody = 'flex';
  seeall = 'none';

  scrWidth: any;

  procedures;

  visibile = 'none';

  categoryLink = 'ALL';

  serviceCountInWidget = 11;

  isMoileView() {
    if (window.innerWidth < 700) {
      return true;
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  seLayoutView() {
  }

  setSeeAllVisiblity( length: number ) {
    if (length >= 4 && this.isMoileView()) {
      this.seeall = 'flex';
    } else if (length >= 7 && !this.isMoileView()) {
      this.seeall = 'flex';
    } else {
      this.seeall = 'none';
    }
  }


  constructor(private procedureService: ProcedureService, private router: Router ) {

    this.seLayoutView();
  }

  ngOnInit() {


    switch(this.category){
      case 'LABORATORY_SERVICE':
        this.title = "Laboratory Test";
        this.subheading = "Book lab tests and get free home collection services within 24 hrs.";
        this.categoryLink = 'LABORATORY SERVICE';
        this.serviceCountInWidget = 7;
        break;
      case 'PROCEDURE':
        this.title = "Procedures";
        this.subheading = "We at Easy my care have procedures by selected providers whom you can trust.";
        this.categoryLink = 'ALL';
        this.serviceCountInWidget = 11;
        break;
      default:
        this.categoryLink = 'ALL';
    }


    this.procedureService.getProcedureMetadata(0, this.isMoileView() === true ? 4 : this.serviceCountInWidget, 'ASC', this.category === 'LABORATORY_SERVICE'? 'LABORATORY SERVICE' :'ALL' ).subscribe(
      (response) => {
        if( ( response as any).count === 0 ) {
          this.loader = 'none';
          this.error = 'initial';
          this.seeall = 'none';
        } else {
          this.errorBody = 'none';
          this.procedures = (response as any).procedureMetadata;
          this.setSeeAllVisiblity(this.procedures.length);
        }
      },
      () => {
        this.loader = 'none';
        this.error = 'initial';
        this.seeall = 'none';
      }
    );

  }

  handleClick(event: Event, procedure: any) {
    this.router.navigateByUrl('/procedure/' + procedure._id);
  }


}
