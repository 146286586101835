import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-flow',
  templateUrl: './business-flow.component.html',
  styleUrls: ['./business-flow.component.scss']
})
export class BusinessFlowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
