import { VideoCallServiceService } from './../services/videoCallSerice/video-call-service.service';
import { SseService } from './../services/sse/sse.service';
import { Component, OnInit, OnChanges, OnDestroy, Input, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {ChangeDetectorRef} from '@angular/core';
import { connect, createLocalVideoTrack } from 'twilio-video';




@Component({
  selector: 'app-live-video-consultation',
  templateUrl: './live-video-consultation.component.html',
  styleUrls: ['./live-video-consultation.component.scss']

})
export class LiveVideoConsultationComponent implements OnInit, OnDestroy {

  data = new Map();

  constructor(private sseService: SseService, private videoCallServiceService: VideoCallServiceService) {
    this.data = new Map();
  }

  ngOnInit() {

    this.sseService.getServerSentEvent('notifications/platform/live/events/status').subscribe(data => {

      let eventData = JSON.parse((data as any).data);

      if (eventData.status === 'online') {
          this.data.set(eventData._id, eventData);
      } else if (eventData.status === 'offline') {
        this.data.has(eventData._id) ? this.data.delete(eventData._id) : console.log('event waste') ;
    }
    });
  }

  makeCall(event) {

    this.videoCallServiceService.makeCall(event).subscribe(token => {
        this.connectCall(token);
    });


  }

  connectCall(token) {

    let previewContainer = document.getElementById('local-media');
    let remoteContainer = document.getElementById('remote-media');


    createLocalVideoTrack().then(track => {
      previewContainer.appendChild(track.attach());
      previewContainer.getElementsByTagName('video')[0].style.width = '100%';
      previewContainer.getElementsByTagName('video')[0].style.height = '100%';
    });

    console.log('Connecting to : ' + JSON.stringify(token));
    connect(token.token, { name: token.roomName }).then(room => {
      console.log(`Successfully joined a Room: ${room}`);
      console.log('room details: ' + room);
      room.on('participantConnected', participant => {
        console.log(`A remote Participant connected: ${participant}`);
        participant.on('trackSubscribed', track => {
          remoteContainer.appendChild(track.attach());
          remoteContainer.getElementsByTagName('video')[0].style.width = '100%';
          remoteContainer.getElementsByTagName('video')[0].style.height = '100%';
        });
      });
      room.participants.forEach(participant => {
        participant.tracks.forEach(publication => {
          if (publication.track) {
            remoteContainer.appendChild(publication.track.attach());
          }
        });
        participant.on('trackSubscribed', track => {
          remoteContainer.appendChild(track.attach());
        });
      });
    }, error => {
      console.error(`Unable to connect to Room: ${error}`);
    });
  }

ngOnDestroy() {
  }












}
