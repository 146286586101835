import { Component, OnInit } from '@angular/core';
import { Gallery } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  constructor(public lightbox: Lightbox, public gallery: Gallery) { }

  ngOnInit() {
    this.loadEvent();
  }


  loadEvent() {
    // Get a lightbox gallery ref
        const lightboxRef =  this.gallery.ref('lightbox');
        // Load items into the lightbox gallery ref
        lightboxRef.reset();
        lightboxRef.addVideo({
          src: 'https://easy-my-care-public-web.s3.ap-south-1.amazonaws.com/video/3ce29259-2929-46ce-b4e4-10165dab57bf.mov'
        });
        lightboxRef.play(0);
      }

}
