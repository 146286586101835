import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material';


@Component({
  selector: 'app-login',
  templateUrl: './login.component copy.html',
  styleUrls: ['./login.component copy.scss']
})
export class LoginComponent implements OnInit, OnChanges {

  public auth2: any;

  form: FormGroup;

  cartId: string;

  email: string;
  password: string;
  error: string;

  BACK_LINK: string;

  loginWithPhoneNumber = true;

  @Input()
  bottomSheet: MatBottomSheet;

  @Input()
  isloginFormWidth = false;

  constructor(public ngZone: NgZone,private loginService: LoginService, private router: Router, private location: Location,
              private formBuilder: FormBuilder ,private _snackBar: MatSnackBar) {

    if (this.router.getCurrentNavigation() != null && (this.router.getCurrentNavigation().extras as any).cartId !== undefined) {
      this.cartId = (this.router.getCurrentNavigation().extras as any).cartId;
    }

    if (this.router.getCurrentNavigation() != null && (this.router.getCurrentNavigation().extras as any).queryParams !== undefined && (this.router.getCurrentNavigation().extras as any).queryParams.BACK_LINK !== undefined) {
      this.BACK_LINK = (this.router.getCurrentNavigation().extras as any).queryParams.BACK_LINK;
    }


  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  public gSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {
        console.log('calling server...');
        this.ngZone.run( () => {
          let profile = googleUser.getBasicProfile();
          let token = googleUser.getAuthResponse().id_token;
          console.log(profile);
          console.log('EXP:' + googleUser.getAuthResponse().expires_in);

          this.loginService.googleSSO(token).subscribe((response)=>{
              if (response === null) {
                this.error = 'wrong email or password';
              } else {
                this.loginService.loggedIn(true);
                console.log('From Login' + this.cartId);
                // if (this.cartId !== undefined && this.cartId !== null) {
                //   console.log(this.cartId);
                //   let navigationExtras: NavigationExtras = {
                //     queryParams: {
                //       cartId: this.cartId
                //     }
                //   };
                //   this.router.navigateByUrl('/checkout', navigationExtras);
                //   return;
                // }

                if(this.bottomSheet!=undefined){
                  this.bottomSheet.dismiss();
                }


                // if(this.BACK_LINK != undefined){
                //   this.router.navigateByUrl('/'+this.BACK_LINK);
                //   console.log(this.BACK_LINK);
                //   return;
                // }
                // this.router.navigateByUrl('/home');
                // this.router.navigateByUrl('/refresh', { skipLocationChange: true}).then(
                //   () => {
                //     this.router.navigate([decodeURI(this.location.path())]);
                //   }
                // );
              }
            }, error => {
              this.error = 'wrong email or password';
            });
          // console.log('Token || ' + googleUser.getAuthResponse().id_token);
          // console.log('ID: ' + profile.getId());
          // console.log('Name: ' + profile.getName());
          // console.log('Image URL: ' + profile.getImageUrl());
          // console.log('Email: ' + profile.getEmail());

          })
        },
        (error) => {
          alert(JSON.stringify(error, undefined, 2));
        });
      }

      public googleInit() {
        (window as any).gapi.load('auth2', () => {
          this.auth2 = (window as any).gapi.auth2.init({
            client_id: '88038751231-jjogi6kbnospfvp99ruspmgr4vh8c0nv.apps.googleusercontent.com',
            cookiepolicy: 'single_host_origin',
            scope: 'profile email'
          });
          this.gSignin(document.getElementById('googleBtn'));
        });
      }


  signOut() {
    var auth2 = (window as any).gapi.auth2.getAuthInstance();
    auth2.signOut().then( () => {
      console.log('User signed out.');
    });
  }

  ngOnInit() {
    this.googleInit();
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, this.noWhitespaceValidator]],
    });
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { noWhitespaceValidator: true };
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['snackbar_no_action']
    });
  }

  handleClick(event: Event) {

    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      this.openSnackBar("Please enter login details",null);

    } else {
    this.error = '';
    this.loginService.login(this.email, this.password).subscribe(
      (response) => {
        if (response === null) {
          this.error = 'wrong email or password';
          this.openSnackBar("Wrong Email or Password",null);
        } else {
          this.loginService.loggedIn(true);
          console.log('From Login' + this.cartId);
          // if (this.cartId !== undefined && this.cartId !== null) {
          //   console.log(this.cartId);
          //   let navigationExtras: NavigationExtras = {
          //     queryParams: {
          //       cartId: this.cartId
          //     }
          //   };
          //   this.router.navigateByUrl('/checkout', navigationExtras);
          //   return;
          // }


          if(this.bottomSheet!=undefined){
            this.bottomSheet.dismiss();
          }


          // if(this.BACK_LINK != undefined){
          //   this.router.navigateByUrl('/'+this.BACK_LINK);
          //   console.log(this.BACK_LINK);
          //   return;
          // }
          // this.router.navigateByUrl('/home');
          // this.router.navigateByUrl('/refresh', { skipLocationChange: true}).then(
          //   () => {
          //     this.router.navigate([decodeURI(this.location.path())]);
          //   }
          // );
        }
      }, error => {
        this.error = 'wrong email or password';
        this.openSnackBar("Wrong Email or Password",null);
      });
    }
  }

  googleLogin($event){
    console.log($event);
  }

}
