import { Injectable, NgZone } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { ConstantsService } from '../../common/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class SseService {


  private getEventSource(url: string): EventSource {
    return new EventSource(url);
  }

  constructor(private _zone: NgZone) { }



  getServerSentEvent(url: string){
    return new Observable((observer) => {
      const eventSource = this.getEventSource(ConstantsService.baseEndpoint + url);

      eventSource.onmessage = event => {
          this._zone.run(() => {
            observer.next(event);
          });
        };

      eventSource.onerror = error => {
          this._zone.run(() => {
            // eventSource.close();
            // observer.
            // observer.error(error);
          });
        };
    });
  }
}
