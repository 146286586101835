import { AppComponent } from './../app.component';
import { LocationService } from './../services/location/location.service';
import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component copy 2.html',
  styleUrls: ['./footer.component copy 2.scss']
})
export class FooterComponent implements OnInit {

  isMobileView = false;
  colspan = 1;

  @HostListener('window:resize', ['$event'])
  setLayoutView() {
    if (window.innerWidth < 700) {
     this.isMobileView = true;
     this.colspan = 2;

    } else {
      this.isMobileView = false;
      this.colspan = 1;
    }
  }

  constructor( private locationService: LocationService, private appComponent: AppComponent) {
   }

  ngOnInit() {
  }

  setLocation( location ) {
    if ( 'Current Location' === location ) {
      this.locationService.getPosition().then(pos => {
        this.appComponent.saveInLocal('lon', `${pos.lng}`);
        this.appComponent.saveInLocal('lat', `${pos.lat}`);
        this.appComponent.saveInLocal('city', null);
        this.appComponent.saveInLocal('selected_item', 'Current Location');
      });
      return;
    }
    if ( 'All' === location ) {
        this.appComponent.saveInLocal('lon', null);
        this.appComponent.saveInLocal('lat', null);
        this.appComponent.saveInLocal('city', null);
        this.appComponent.saveInLocal('selected_item', 'All');
        return;
    }
    this.appComponent.saveInLocal('city', location);
    this.appComponent.saveInLocal('lon', null);
    this.appComponent.saveInLocal('lat', null);
    this.appComponent.saveInLocal('selected_item', location);
  }

}
