import { AppComponent } from './../../app.component';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormServiceService {

  constructor(private httpClient: HttpClient, private appComponent: AppComponent) { }


  public getDynamicForm(id) {
    return this.httpClient.get(ConstantsService.baseEndpoint + '/dynamic/page/' + id);
}
public submitForm(formResponse) {
  return this.httpClient.post(ConstantsService.baseEndpoint + '/dynamic/page/user-response', formResponse,{withCredentials:true});
}

}
