import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';



@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit {

  columnNum = 18;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  setLayoutView() {

    if (window.innerWidth < 700) {
      this.columnNum = 9;
    } else {
      this.columnNum = 18;
    }
  }
  constructor() {
    this.setLayoutView();
  }

  ngOnInit() {
  }

}
