import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private httpClient: HttpClient) { }


  public updateUser(user: UserDetails) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'user/update',user, { withCredentials: true });
  }


  public updateProfilePic(file: File) {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.httpClient.post(ConstantsService.baseEndpoint + 'user/change/profile',formData, { withCredentials: true });

  }



}

export class UserDetails {

  emcId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber: string;
  gender: string;
  email: string;
  userEmail: string;
  dob: string;
  password: string;
  confirmPassword: string;
  city: string;
  pincode: string;
  address: string;
  otp: string;
  profileImage: string;
  canUpdateEmailAddress: boolean;
  canUpdatePhoneNumber: boolean;
}
