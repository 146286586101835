import { AppComponent } from './../../app.component';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private httpClient: HttpClient, private appComponent: AppComponent) {
  }

  public getEvents() {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'events/get');
}

public getEvent(id) {
  return this.httpClient.get(ConstantsService.baseEndpoint + 'events/get/' + id);
}
}
