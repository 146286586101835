import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LoginService } from '../services/login/login.service';
import { UserDetails } from '../services/login/login.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';





@Component({
  selector: 'app-singup',
  templateUrl: './singup.component.html',
  styleUrls: ['./singup.component.scss']
})


export class SingupComponent implements OnInit, OnDestroy, OnChanges {

  form: FormGroup;

  cartId: string;

  userDetails: UserDetails;
  loader: string;
  singuptext: string;
  error: string;

  isLoggedIn;

  subscription: Subscription;


  constructor(private loginService: LoginService, private router: Router, private activeRoute: ActivatedRoute,
              private formBuilder: FormBuilder, private _snackBar: MatSnackBar ) {
    this.userDetails = new UserDetails();
    this.loader = 'none';
    this.error = '';

    this.cartId = this.activeRoute.snapshot.paramMap.get('id');

  }
  ngOnChanges() {
    console.log(this.isLoggedIn);
    // console.log(this.loginService.isLoggedIn);
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription = this.loginService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(isLoggedIn){
        this.router.navigateByUrl('/home');
      }
    });
    this.form = this.formBuilder.group({
      firstname: [null, [Validators.required, this.noWhitespaceValidator]],
      lastname: [null ],
      phonenumber: [null, [Validators.required]],
      gender: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, this.noWhitespaceValidator]],
      cpassword: [null, [Validators.required, this.confirmPassword, this.noWhitespaceValidator ]],
      city: [null, [Validators.required, this.noWhitespaceValidator]],
      pincode: [null, Validators.required],
      address: [null, [Validators.required, this.noWhitespaceValidator]],
    });
  }


  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { noWhitespaceValidator: true };
  }

  public confirmPassword(control: FormControl) {
    const isSame = (control.value === (document.getElementById('password') as any).value);
    return isSame ? null : { confirmPassword: true };
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  handleClick(event: Event) {

    console.log(this.form.get('gender').value);

    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
        this.openSnackBar('Fill all the details', null);
      });

    } else {

      if(this.form.get('password').value !== this.form.get('cpassword').value) {
        this.form.get('cpassword');
        this.openSnackBar('Please confirm your password!!', null);
        this.error = 'Please confirm your password!!';
        return;
      }
      this.error = '';
      this.loader = 'initial';
      this.singuptext = 'hidden';

      // Setting up user DTO

      this.userDetails.firstName = this.form.get('firstname').value;
      this.userDetails.lastName = this.form.get('lastname').value;
      this.userDetails.phoneNumber = this.form.get('phonenumber').value;
      this.userDetails.gender = this.form.get('gender').value;
      this.userDetails.email = this.form.get('email').value;
      this.userDetails.password = this.form.get('password').value;
      this.userDetails.confirmPassword = this.form.get('cpassword').value;
      this.userDetails.city = this.form.get('city').value;
      this.userDetails.pincode = this.form.get('pincode').value;
      this.userDetails.address = this.form.get('address').value;

      this.loginService.singup(this.userDetails).subscribe(
      (response) => {
        this.loader = 'none';
        this.singuptext = 'visible';
        this.error = '';
        if ((response as any).statusCode === '301') {
          this.error = (response as any).error;
        } else {
            this.loginService.loggedIn(true);
            if (this.cartId !== undefined && this.cartId !== null) {
              let navigationExtras: NavigationExtras = {
                queryParams: {
                  cartId: this.cartId
                }
              };
              this.router.navigateByUrl('/checkout', navigationExtras);
              return;
            }
            this.router.navigateByUrl('/home');
        }
      },
      (error) => {
        this.error = error.error.message;
        this.loader = 'none';
        this.singuptext = 'visible';
      }
    );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: ['snackbar_no_action']
    });
  }
}


