import { EventsService } from '../services/events/events.service';
import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-digital-camp',
  templateUrl: './digital-camp.component.html',
  styleUrls: ['./digital-camp.component.scss']
})
export class DigitalCampComponent implements OnInit {
  events;

  @HostListener('window:resize', ['$event'])
  setLayoutView() {
  }
  constructor(private router: Router, public eventsService: EventsService) {
    this.setLayoutView();
  }

  ngOnInit() {
    this.eventsService.getEvents().subscribe(
      (events) => {
        if ( (events as any[]).length > 0 ) {
          this.events = events;
        } else {
        }
      }
    );
  }

  openLink(event){
    switch(event.linkType){
      case 'DYNAMIC_FORM':
        this.router.navigateByUrl('/page' + '/' + event._id, event);
        break;
      case 'EXTERNAL_LINK':
        window.open(event.externalLink, '_blank');
        break;
    }
  }
}
