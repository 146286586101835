import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private httpClient: HttpClient) { }

  private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private showLoginSheet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private refreshUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoggedIn$: Observable<boolean> = this.isLoggedIn.asObservable();

  showLoginSheet$: Observable<boolean> = this.showLoginSheet.asObservable();

  refreshUser$: Observable<boolean> = this.refreshUser.asObservable();

  public loggedIn(val: boolean) {
    this.isLoggedIn.next(val);
  }

  public loginSheet(val: boolean) {
    this.showLoginSheet.next(val);
  }

  public isRefreshUser(val: boolean) {
    this.refreshUser.next(val);
  }

  public singup(userDetails: UserDetails) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'login/singup',
    userDetails, { withCredentials: true });
  }

  public googleSSO(token) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', token);

    return this.httpClient.get(ConstantsService.baseEndpoint + 'login/sso/google?token=' +  token,
    { withCredentials: true});
  }



  public guestLogin(userDetails: UserDetails) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'login/guestLogin',
    userDetails, { withCredentials: true });
  }

  public otp(userDetails: UserDetails) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'login/OTP',
    userDetails, { withCredentials: true });
  }

  public login(email: string, password: string) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'login/in',
    { email, password}, { withCredentials: true });
  }

  public getUser() {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'login/user', { withCredentials: true });
  }

  public checkStatus() {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'login/status',
    { withCredentials: true } );
  }

  public logout() {
    return this.httpClient.get(ConstantsService.baseEndpoint + 'login/out',
    { withCredentials: true } );
  }




}

export class UserDetails {

  firstName: string;
  lastName: string;
  phoneNumber: string;
  gender: string;
  email: string;
  dob: string;
  password: string;
  confirmPassword: string;
  city: string;
  pincode: string;
  address: string;
  otp: string;
}
