import { AppComponent } from './../../app.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';



@Injectable({
  providedIn: 'root'
})
export class QuickSearchService {

  lat;
  lon;
  city;

  constructor(private httpClient: HttpClient, private appComponent: AppComponent) { }

  public quickSearch(key: string) {

    this.lat = this.appComponent.getFromLocal('lat');
    this.lon = this.appComponent.getFromLocal('lon');
    this.city = this.appComponent.getFromLocal('city');

    const searchResult =  this.httpClient.get(ConstantsService.baseEndpoint + 'quicksearch/' + key + '?' + 'lat='
    + this.lat + '&lon=' + this.lon + '&city=' + this.city)
    .pipe(debounceTime(500),
    map(
      (data: any) => {
        return (
          data.length !== 0 ? data as any[] : [{entityName: 'nothing found', entityId: null} as any]
          );
        }
      ));
    return searchResult;
  }

}
