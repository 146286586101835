import { AppComponent } from './../../app.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from '../../common/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';



@Injectable({
  providedIn: 'root'
})
export class VideoCallServiceService {

  constructor(private httpClient: HttpClient) { }

  public makeCall(agentProfile: any) {
   return this.httpClient.post(ConstantsService.baseEndpoint + 'call/management/call',
   agentProfile, { withCredentials: true});
  }

}
