import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

   columnNum = 18;
   colspan = 3;

  //https://github.com/devmark/angular-slick-carousel/blob/master/dist/angular-slick.js

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
  };

  @HostListener('window:resize', ['$event'])
  setLayoutView() {

    if (window.innerWidth < 700) {
      this.slideConfig.slidesToShow = 2;
    } else {
      this.slideConfig.slidesToShow = 5;
    }
  }
  constructor() {
    this.setLayoutView();
  }

  ngOnInit() {
  }






}
