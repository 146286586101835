import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { ProcedureService } from '../services/procedure/procedure.service';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-allprocedure',
  templateUrl: './allprocedure.component copy.html',
  styleUrls: ['./allprocedure.component copy.scss']
})

export class AllprocedureComponent implements OnInit {

  loader = 'initial';
  error = 'none';
  errorBody = 'flex';
  seeall = 'none';

  category = 'All';

  page = 0;

  columnNum = 4;
  scrWidth: any;

  procedures;

  visibile = 'none';

  pagination = false;

  title = '';

  subheading = '';

   // previousButton = '#EAEBED';
   previousButton = 'rgb(0, 108, 141)';
   nextButton = 'rgb(0, 108, 141)';

   previousButtonClick = 'none';
   nextButtonClick = 'none';

   hasNext = false;
   hasPrevious = false;




  showPaginationBar() {
    return this.pagination;
  }

  isMoileView() {
    if (window.innerWidth < 700) {
      return true;
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  seLayoutView() {
    if (this.isMoileView()) {
      this.columnNum = 2;
    } else {
      this.columnNum = 3;
    }
  }

  setSeeAllVisiblity( length: number ) {
    if (length >= 4 && this.isMoileView()) {
      this.seeall = 'block';
    } else if (length >= 8 && !this.isMoileView()) {
      this.seeall = 'block';
    } else {
      this.seeall = 'none';
    }
  }


  constructor(private procedureService: ProcedureService, private router: Router, public activateRoute: ActivatedRoute, public router2: Router ) {
    this.seLayoutView();
    activateRoute.params.subscribe(val => {
      this.category = decodeURIComponent(this.activateRoute.snapshot.paramMap.get('id'));
      this.getProcedureList( 0 , 'ASC');
    });
  }

  ngOnInit() {
    this.category = decodeURIComponent(this.activateRoute.snapshot.paramMap.get('id'));
    this.getProcedureList( 0 , 'ASC');
  }

  handleClick(event: Event, procedure: any) {
    this.router.navigateByUrl('/procedure/' + procedure._id);
  }

  handleNext(event: Event) {
    this.getProcedureList( ++this.page , 'ASC');
  }

  handlePrevious(event: Event) {
    this.getProcedureList( --this.page , 'ASC');
  }

  getProcedureList(page, sortOrder ) {
    window.scrollTo(0, 0);

    this.procedureService.getProcedureMetadata(page, this.isMoileView() === true ? 4 : 16, sortOrder, this.category ).subscribe(
      (response) => {
        if( ( response as any).count === 0 ) {
          this.loader = 'none';
          this.error = 'initial';
          this.errorBody = 'flex';
          this.seeall = 'none';
          this.procedures = undefined;
        } else {
          this.errorBody = 'none';
          this.page = (response as any).page;
          this.procedures = (response as any).procedureMetadata;
          this.setSeeAllVisiblity(this.procedures.length);
        }
        // Previous Button
        if((response as any).hasPrevious === true) {
          this.previousButton = 'rgb(0, 108, 141)';
          this.previousButtonClick = 'all';
          this.pagination = true;
          this.hasPrevious = true;
        } else {
          this.hasPrevious = false;
          this.previousButton = '#ababab';
          this.previousButtonClick = 'none';
        }

        // Next Button
        if((response as any).hasNext === true) {
          this.nextButton = 'rgb(0, 108, 141)';
          this.nextButtonClick = 'all';
          this.pagination = true;
          this.hasNext  = true;
        } else {
          this.hasNext  = false;
          this.nextButton = '#ababab';
          this.nextButtonClick = 'none';
        }
      },
      () => {
        this.loader = 'none';
        this.error = 'initial';
        this.seeall = 'none';
      }
    );
  }


}
