import { AppComponent } from './../app.component';
import { LocationService } from './../services/location/location.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, interval } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import { QuickSearchService } from '../services/quickSearch/quick-search.service';
import { Router } from '@angular/router';


export interface Procedure {
  entityName: string;
  entityId: string;

}

@Component({
  selector: 'app-banner-search',
  templateUrl: './banner-search.component-tbr copy.html',
  styleUrls: ['./banner-search.component.scss']
})
export class BannerSearchComponent implements OnInit {

  selectedProcedure;

  searchIcon = 'fa-search'

  searchIcons = ['fa-search','fa-microscope','fa-user-md']

  stateCtrl = new FormControl();

  filteredProcedures: Observable<Procedure[]>;



  procedures: Procedure[] = [
  ];

  constructor(private router: Router, private qickSearchService: QuickSearchService, private locationService: LocationService,
              private appComponent: AppComponent) {
    this.stateCtrl.valueChanges.subscribe(
      key => {
        if ( key.length >= 3 ) {
          if (key !== '') {
            this.qickSearchService.quickSearch(key).subscribe(
              (data) => {
                this.procedures = data;
              });
            }
        } else {
          this.procedures = [];
        }
    });
  }

  ngOnInit() {
    this.changeSearchIcon();
  }

  openDeails( entityId ) {
    if (entityId != undefined && entityId !== null) {
      this.router.navigateByUrl('/procedure/' + entityId);
    }
  }

  setLocation( location ) {
    if ( 'Current Location' === location ) {
      this.locationService.getPosition().then(pos => {
        this.appComponent.saveInLocal('lon', `${pos.lng}`);
        this.appComponent.saveInLocal('lat', `${pos.lat}`);
        this.appComponent.saveInLocal('city', null);
        this.appComponent.saveInLocal('selected_item', 'Current Location');
      });
      return;
    }
    if ( 'All' === location ) {
        this.appComponent.saveInLocal('lon', null);
        this.appComponent.saveInLocal('lat', null);
        this.appComponent.saveInLocal('city', null);
        this.appComponent.saveInLocal('selected_item', 'All');
        return;
    }
    this.appComponent.saveInLocal('city', location);
    this.appComponent.saveInLocal('lon', null);
    this.appComponent.saveInLocal('lat', null);
    this.appComponent.saveInLocal('selected_item', location);
  }

  isSelected( location ) {
      return this.appComponent.getFromLocal('selected_item') === location;
  }

  changeSearchIcon() {
    interval(2000).subscribe(
      (val) => {
        var currentIndex = this.searchIcons.indexOf(this.searchIcon);
        if( currentIndex + 1 == this.searchIcons.length){
          currentIndex = -1;
        }
        this.searchIcon = this.searchIcons[currentIndex+1];
      });
  }

  searchedProcedure(procedure: Procedure) {
    this.selectedProcedure = procedure;
  }

}
