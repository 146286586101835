import { Coupon } from './../services/coupon/coupon.service';
import { CouponDialog } from './../dialog/coupon/coupon-dialog';
import { CheckoutDialog } from './../dialog/checkout/checkout-dialog';
import { MatDialog } from '@angular/material';
import { ConstantsService } from './../common/services/constants.service';
import { WindowRefService } from './../services/windowRef/window-ref.service';
import { Component, OnInit, OnDestroy, NgZone, HostListener } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { CartService, Payment } from '../services/cart/cart.service';
import { LoginService } from '../services/login/login.service';
import { error } from 'protractor';
import { CheckoutService } from 'paytm-blink-checkout-angular';
import { Subscription } from 'rxjs';
import { ICustomWindow } from '../services/windowRef/window-ref.service';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {


  cartItems = [];


  procedureExtra: any;
  procedure: {
    procedure: any;
    hospital: any;
    price: any;
    rOrderId: string;
  };

  hasPrevious = false;
  hasNext = false;

  cartData = [];

  page = 0;

  pagination = false;


  constructor(private cartService: CartService) {


  }


  ngOnInit() {

    this.getCartList(this.page);
  }

  ngOnDestroy(): void {

  }

  showPaginationBar() {
    return this.pagination;
  }

  handleNext(event: Event) {
    this.getCartList(++this.page);
  }

  handlePrevious(event: Event) {
    this.getCartList(--this.page);
  }

  getCartList( page ) {

    const pageSize = 20;

    this.cartService.cartDetails(page,pageSize).subscribe(
      (response) => {
        this.cartData = (response as any).data;
        this.page = (response as any).page;
        this.hasNext = (response as any).hasNext;
        this.hasPrevious = (response as any).hasPrevious;
      });
  }



}
