import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './../../common/services/constants.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private httpClient: HttpClient) { }

  public applyCoupon(couponId,cartItems) {
    return this.httpClient.post(ConstantsService.baseEndpoint + 'coupon/apply?couponId=' + couponId, cartItems ,{ withCredentials: true});
  }
}


export class Coupon {

  id : string;
  couponId : string;
  name : string;
  details : string;
  discountPercentage : number;
  applicableOn : string[];

}
