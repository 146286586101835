import { LoginService } from 'src/app/services/login/login.service';
import { Coupon } from './../../services/coupon/coupon.service';
import { WindowRefService } from './../../services/windowRef/window-ref.service';
import { ConstantsService } from './../../common/services/constants.service';
import { CartService } from './../../services/cart/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, Inject, NgZone } from '@angular/core';
import { identifierModuleUrl, preserveWhitespacesDefault } from '@angular/compiler';
import { MatDialog, MatDialogRef, MatMenuModule, MAT_DIALOG_DATA } from '@angular/material';




@Component({
  templateUrl: '../checkout/checkout-dialog.html',
  styleUrls: ['../checkout/checkout-dialog.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CheckoutDialog implements OnInit {

  showLoader = true;

  cartItems = [];

  days = [];

  total = 0;

  statusCode = 100;

  coupon : Coupon;

  // Razor Pay
  private rzp : any;

  options = {
    "key": ConstantsService.config.razor_pay_key_id,
    "amount": 0, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "EasyMyCare",
    "description": "Healthcare provider",
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": this.paymentHandler.bind(this),
    "modal":{
      "ondismiss":(()=>{
        this.zone.run( ()=> {
          console.log("error, payment");
        })
      })
    },
    "prefill":
    {
      "name": "",
      "email": "",
      "contact": ""
    },
    "customer":{
    },
    "notes":
    {
      "address": "EASY MY CARE,M15, Ramakrishna Apart, I P EXTN, P.NO 29, DELHI - 110092"
    },
    "theme": {
      "color": "#1F4E8C"
    }
  };

  dataConfig;

  paymentSuccess = false;

  constructor(
    public dialogRef: MatDialogRef<CheckoutDialog>,
    @Inject(MAT_DIALOG_DATA) public data: Object, private _snackBar: MatSnackBar, private cartService: CartService, private zone: NgZone, private winRef: WindowRefService, private loginService: LoginService) {
      // dialogRef.disableClose = true;
      this.cartItems = data["cartItems"];
      this.total = data["total"];
      this.coupon = data["coupon"];

      this.loginService.getUser().subscribe(data =>{
        if(data!=null){
          this.options.prefill.name = (data as any).firstName + (data as any).lastName;
          this.options.prefill.email = (data as any).userEmail;
          this.options.prefill.contact = (data as any).phoneNumber || this.cartItems[0].additional.patientPhoneNumber;
        }
      })

    }

    ngOnInit(): void {
      console.log(this.cartItems);
      this.createCart();
    }

    createCart(){

      this.statusCode = 100;

      console.log(this.coupon);

      this.cartService.buildCart(this.cartItems, (this.coupon != null && this.coupon != undefined) ? this.coupon.couponId:null).subscribe( data => {
        if(data != undefined && data != null && data["orderId"] != undefined && data["orderId"] != null){
          this.showLoader = false;
          this.dataConfig = data;
          console.log(this.dataConfig);
          // this.checkOutButtonHandler = 202;
        } else {
          // this.checkOutButtonHandler = 504;
        }
      })

    }

    doAction(){
      this.dialogRef.close(true)
    }

    closeDialog(){
      this.dialogRef.close(false);
    }

    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
        duration: 2000,
        panelClass: ['snackbar_no_action']
      });
    }

    payment() {

      const paymentId = this.dataConfig["orderId"];
      const price = this.total * 100;

      this.options.order_id = paymentId;
      this.options.amount = price;

      console.log(price);

      if(price > 0){
        this.rzp = new this.winRef.nativeWindow['Razorpay']( this.options );
        this.rzp.open();
      }else{
        this.paymentHandler({
          razorpay_order_id:this.options.order_id,
          razorpay_payment_id:this.dataConfig["receiptId"],
          razorpay_signature:null
        });
      }

    }

    //Success
  paymentHandler( res: any ){
    this.zone.run( ()=> {

      this.showLoader = true;

      this.statusCode = 101;

      console.log("Checking payment status");
      console.log(res);

      const razorpay_order_id = (res as any).razorpay_order_id;
      const razorpay_payment_id = (res as any).razorpay_payment_id;
      const razorpay_signature = (res as any).razorpay_signature;


      this.cartService.cartCheckout(this.dataConfig["receiptId"],{razorpay_order_id,razorpay_payment_id,razorpay_signature}).subscribe(
        ( cartResponse ) => {
          if ( cartResponse === true) {
            console.log("Success");
            this.showLoader = false;
            this.paymentSuccess = true;
          }
        },
        (cartAPIError) =>
        {
          console.log("Error");
        }
        );
      });

  }

}
